import request from '@/utils/request'

export default class ShopApi {
  public async getInfo(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: `/keep-fit/shop-management/${id}`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    })
  }
}
