




































































import {Component, Vue} from 'vue-property-decorator'
import ShopApi from '@/api/shop'
import AdApi from '@/api/ad'
import CoachApi from '@/api/coach'
import ShopImg from '@/components/ShopImg.vue'
import {ImagePreview} from 'vant';

@Component({
  components: {
    ShopImg
  }
})
export default class ShopIndex extends Vue {
  private shopApi = new ShopApi()
  private adApi = new AdApi()
  private coachApi = new CoachApi()
  private shopInfo = {}
  private imgs = []
  private coach = []

  get shopId() {
    return '3a02f182-5aa6-197e-c0e8-2bcbac0a5b11'
  }

  private async activated() {
    await this.getShopInfo()
    await this.getImgs()
    await this.getCoachList()
  }

  private async getShopInfo() {
    if (this.shopId) {
      this.shopInfo = await this.shopApi.getInfo(this.shopId)
    }
  }

  private async getImgs() {
    const result = await this.adApi.getImgAsync(this.shopId)
    if (result) {
      this.imgs = result.items
    }
  }

  private handlePreview(index: number) {
    if (this.imgs.length > 0) {
      const images = this.imgs.map((value: any) => value.fileUrl);
      ImagePreview({images, startPosition: index, closeable: true});
    }
  }

  private async getCoachList() {
    const result = await this.coachApi.getListByState()
    if (result) {
      this.coach = result.items
    }
  }
}
